/* Add these styles to your existing Feedly.css file */

.upload-progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
}

.upload-progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease-in-out;
}

.save-to-s3-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-to-s3-button:hover {
    background-color: #45a049;
}

.save-to-s3-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

.save-to-s3-button.success {
    background-color: #2196f3;
}

.save-to-s3-button.error {
    background-color: #f44336;
}
/* Feedly.css */

.feedly-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: #0a0e17;
    color: #ffffff;
}

.feedly-cyber-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        linear-gradient(rgba(10, 14, 23, 0.9), rgba(10, 14, 23, 0.9)),
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect width="100" height="100" fill="%230a0e17"/><path d="M0 0L100 100M100 0L0 100" stroke="%231a2333" stroke-width="0.5"/></svg>');
    background-size: 10px 10px;
    z-index: -1;
    animation: backgroundScroll 20s linear infinite;
}

@keyframes backgroundScroll {
    0% { background-position: 0 0; }
    100% { background-position: 10px 10px; }
}

.feedly-header {
    background: rgba(26, 35, 51, 0.8);
    backdrop-filter: blur(5px);
    border-bottom: 1px solid #2a3a5a;
    position: relative;
    z-index: 1;
}

.feedly-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.nav-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4caf50;
    text-decoration: none;
    text-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
    transition: color 0.3s ease;
}

.nav-logo:hover {
    color: #66bb6a;
}

.nav-links {
    display: flex;
    gap: 1rem;
}

.nav-link {
    color: #ffffff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.nav-link:hover {
    background-color: rgba(76, 175, 80, 0.2);
}

.feedly-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.main-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #4caf50;
    text-shadow: 0 0 15px rgba(76, 175, 80, 0.7);
}

.feedly-feeds-section {
    background-color: rgba(26, 35, 51, 0.8);
    border-radius: 8px;
    padding: 1.5rem;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
}

.search-container {
    margin-bottom: 1rem;
}

.search-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #4caf50;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
}

.filter-display {
    background-color: rgba(76, 175, 80, 0.2);
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-style: italic;
    color: #a0a0a0;
}

.loading {
    text-align: center;
    padding: 1rem;
    font-style: italic;
    color: #a0a0a0;
}

.error {
    color: #f44336;
    background-color: rgba(244, 67, 54, 0.1);
    border: 1px solid #f44336;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.feedly-feed-list {
    list-style-type: none;
    padding: 0;
}

.feedly-feed-item {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
}

.feedly-feed-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.feedly-feed-title {
    margin: 0 0 0.5rem 0;
}

.feedly-feed-title a {
    color: #4caf50;
    text-decoration: none;
    transition: color 0.3s ease;
}

.feedly-feed-title a:hover {
    color: #66bb6a;
}

.feedly-feed-summary {
    margin: 0 0 0.5rem 0;
    color: #e0e0e0;
}

.feedly-feed-date {
    font-size: 0.8rem;
    color: #a0a0a0;
    margin-bottom: 0.5rem;
}

.feedly-feed-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.view-article-button,
.save-to-s3-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.view-article-button {
    background-color: #2196f3;
    color: white;
}

.view-article-button:hover {
    background-color: #1e88e5;
}

.save-to-s3-button {
    background-color: #4caf50;
    color: white;
}

.save-to-s3-button:hover {
    background-color: #45a049;
}

.save-to-s3-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

.save-to-s3-button.success {
    background-color: #2196f3;
}

.save-to-s3-button.error {
    background-color: #f44336;
}

.upload-progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
}

.upload-progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease-in-out;
}

.load-more-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.load-more-button:hover {
    background-color: #45a049;
}

.load-more-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

.feedly-footer {
    background: rgba(26, 35, 51, 0.8);
    backdrop-filter: blur(5px);
    border-top: 1px solid #2a3a5a;
    text-align: center;
    padding: 1rem;
    font-size: 0.9rem;
    color: #a0a0a0;
    position: relative;
    z-index: 1;
}

@media (max-width: 768px) {
    .feedly-nav {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        margin-top: 1rem;
    }

    .main-title {
        font-size: 2rem;
    }

    .feedly-feeds-section {
        padding: 1rem;
    }

    .feedly-feed-actions {
        flex-direction: column;
    }

    .view-article-button,
    .save-to-s3-button {
        width: 100%;
    }
}